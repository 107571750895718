import { styled } from '@f8n-frontend/stitches';
import { differenceInHours, isFuture, parseJSON } from 'date-fns';

import useCountdown from 'hooks/use-countdown';
import {
  formatDateAsDayOfMonth,
  formatDateTime,
  parseDateToUnix,
} from 'utils/dates/dates';

import { MomentStatus } from 'types/Moment';

import { Dot } from './Dot';
import Text, { TextVariants } from './base/Text';

export default function MomentDynamicLabel(props: {
  // TODO: rename to make purpose clearer
  isBrief?: boolean;
  momentStatus: MomentStatus;
  size?: TextVariants['size'];
  startsAt: string;
}) {
  const { momentStatus, startsAt, size, isBrief } = props;

  const countdown = useCountdown(parseDateToUnix(startsAt));

  const formattedDates = {
    relative: formatDynamicLabelIsoTimestamp(startsAt),
    abbreviated: formatDateAsDayOfMonth(parseJSON(startsAt)),
  };

  if (
    momentStatus === 'UPCOMING' &&
    isUpcoming24h(startsAt) &&
    countdown.message
  ) {
    return (
      <DateText size={size} css={{ color: '$upcoming' }}>
        Opens in {countdown.message}
      </DateText>
    );
  }

  if (momentStatus === 'UPCOMING') {
    return (
      <DateText size={size} css={{ color: '$upcoming' }}>
        {formattedDates.relative}
      </DateText>
    );
  }

  if (momentStatus === 'SOLD_OUT') {
    return (
      <DateText size={size}>
        {!isBrief && (
          <>
            {formattedDates.abbreviated}{' '}
            <Dot size={1} css={{ backgroundColor: 'currentColor' }} />
          </>
        )}
        <Text as="span" css={{ color: '$unavailable' }}>
          Sold out
        </Text>
      </DateText>
    );
  }

  if (momentStatus === 'OPEN' || momentStatus === 'LIVE') {
    return (
      <DateText size={size}>
        {!isBrief && (
          <>
            {formattedDates.abbreviated}{' '}
            <Dot size={1} css={{ backgroundColor: 'currentColor' }} />
          </>
        )}
        <Text as="span" css={{ color: '$available' }}>
          Open
        </Text>
      </DateText>
    );
  }

  return <DateText size={size}>{formattedDates.abbreviated}</DateText>;
}

export function MomentDynamicDate(props: {
  size?: TextVariants['size'];
  startsAt: string;
}) {
  const { startsAt, size } = props;

  const isMomentUpcoming = isUpcoming(startsAt);

  const countdown = useCountdown(parseDateToUnix(startsAt));

  const formattedDates = {
    relative: formatDynamicLabelIsoTimestamp(startsAt),
    abbreviated: formatDateAsDayOfMonth(parseJSON(startsAt)),
  };

  if (isMomentUpcoming && isUpcoming24h(startsAt) && countdown.message) {
    return (
      <DateText size={size} css={{ color: '$upcoming' }}>
        Opens in {countdown.message}
      </DateText>
    );
  }

  if (isMomentUpcoming) {
    return (
      <DateText size={size} css={{ color: '$upcoming' }}>
        {formattedDates.relative}
      </DateText>
    );
  }

  return <DateText size={size}>{formattedDates.abbreviated}</DateText>;
}

const isUpcoming = (timestamp: string) => {
  return isFuture(parseJSON(timestamp));
};

const isUpcoming24h = (timestamp: string) => {
  return (
    isUpcoming(timestamp) &&
    differenceInHours(parseJSON(timestamp), Date.now()) < 24
  );
};

const formatDynamicLabelIsoTimestamp = (timestamp: string) => {
  return formatDateTime(parseJSON(timestamp));
};

const DateText = styled(Text, {
  color: '$black50',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
});

DateText.defaultProps = {
  size: {
    '@initial': 4,
    '@bp2': 6,
  },
};
