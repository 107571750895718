import Text, { TextVariants } from 'components/base/Text';

export default function PresentedBy(props: {
  name: string;
  size?: TextVariants['size'];
}) {
  const { name, size } = props;
  return (
    <Text
      size={size}
      color="dim"
      weight="regular"
      css={{ paddingTop: '$3' }}
      ellipsis
    >
      Presented by{' '}
      <Text as="span" color="strong">
        {name}
      </Text>
    </Text>
  );
}
