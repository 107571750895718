import { format, parseJSON } from 'date-fns';

import { MomentStatus } from 'types/Moment';

import { isUpcoming } from './dates/dates';

export const getMomentStatus = (options: {
  startsAt: string;
  liveActivityCount: number;
  isSoldOut: boolean;
}): MomentStatus => {
  if (isUpcoming(options.startsAt)) {
    return 'UPCOMING';
  } else if (options.liveActivityCount > 0) {
    return 'LIVE';
  } else if (options.isSoldOut) {
    return 'SOLD_OUT';
  } else {
    return 'OPEN';
  }
};

type BasicEventType = {
  startsAt: string;
};

export const groupByStartDate = <Type extends BasicEventType>(
  moments: Type[]
): Record<string, Type[]> => {
  const initialCalendar: Record<string, Type[]> = {};

  return moments.reduce((acc, moment) => {
    const date = parseJSON(moment.startsAt);

    const dateKey = format(date, 'yyyy-MM-dd');
    const existingMoments = acc[dateKey] || [];

    return {
      ...acc,
      [dateKey]: [...existingMoments, moment],
    };
  }, initialCalendar);
};
