import { styled } from '@f8n-frontend/stitches';

export const Dot = styled('div', {
  borderRadius: '$round',
  background: '$black20',
  flexShrink: 0,

  variants: {
    size: {
      0: {
        width: '2px',
        height: '2px',
      },
      1: {
        width: '4px',
        height: '4px',
      },
    },
  },

  defaultVariants: {
    size: 1,
  },
});
