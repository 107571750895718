import { styled } from '@f8n-frontend/stitches';

import Flex from 'components/base/Flex';

export const ArtworkUnsupportedMessageWrapper = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$round',
  // Used to blur out the canvas background lines
  // Note: does not work on FF, but design degrades gracefully
  backdropFilter: 'blur(10px)',
  '& svg': {
    width: '24px',
    height: '24px',
    color: '$white100',
  },

  variants: {
    backgroundColor: {
      light: {
        background: '$black20',
      },
      dark: {
        background: '$white20',
      },
    },
  },

  defaultVariants: {
    backgroundColor: 'dark',
  },
});
