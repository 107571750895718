import { createCssTarget } from 'utils/styles';

import Image from './base/Image';

interface MomentMediaProps {
  optimizedPosterUrl: string;
}
export const MOMENT_MEDIA_TARGET = createCssTarget('moment-media');

export default function MomentMedia(props: MomentMediaProps) {
  const { optimizedPosterUrl } = props;
  return (
    <Image
      className={MOMENT_MEDIA_TARGET.className}
      src={optimizedPosterUrl}
      alt=""
      css={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        transition: 'transform $1 $ease',
        display: 'block',
      }}
    />
  );
}
