import { styled } from '@f8n-frontend/stitches';
import { useRef } from 'react';

import useBalancedMedia from 'hooks/use-balanced-media';
import useHasIntersected from 'hooks/use-has-intersected';
import useLoadedPreviewMedia from 'hooks/use-loaded-preview-media';
import { DEFAULT_VIDEO_PROPS } from 'lib/constants';
import { repeat } from 'utils/helpers';
import { createClassSelector } from 'utils/styles';

import { AssetSummary, LaunchMediaAsset } from 'types/media';

import Media from './Media';
import DebugBalancedMedia from './debug/DebugBalancedMedia';

// Toggle this on in development to debug sizing issues
const DEBUG = false;

// Unlike other stacks, where the asset controls the aspect ratio, this stack is always square
const STACK_ASPECT_RATIO = '1 / 1';

const MEDIA_STACK_HORIZONTAL_CLASSNAME = 'media-stack-horizontal';
export const MEDIA_STACK_HORIZONTAL_SELECTOR = createClassSelector(
  MEDIA_STACK_HORIZONTAL_CLASSNAME
);

type MediaStackHorizontalProps = {
  media: LaunchMediaAsset;
  /**
   * Number of stacked images
   * 3 = 1 main image + 1 on each side
   * 5 = 1 main image + 2 on each side
   */
  count?: 1 | 3 | 5;
  /**
   * Used to make the padding between the images equal when displayed in a grid
   * e.g. when a stack with a count of 3 is displayed adjacent to a stack with a count of 5
   * pass equalPadding={5} to the stack with a count of 3 to make them horizontally aligned.
   */
  equalPadding?: 1 | 3 | 5;
  /**
   * Color applied to images inside the Stack. Typically should match the background color.
   * Will inherit the parent's background color if not provided. If the parent uses a semi-opaque
   * background color, it's best to pass in a solid tintColor.
   */
  tintColor?: string;
  hasBalancedMedia?: boolean;
};

const MODIFIER = 20;

export default function MediaStackHorizontal(props: MediaStackHorizontalProps) {
  const {
    hasBalancedMedia = true,
    media,
    count = 3,
    equalPadding,
    tintColor,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const hasIntersected = useHasIntersected(containerRef, { threshold: 0.3 });
  const loadedMedia = useLoadedPreviewMedia(media, { enabled: hasIntersected });

  const asset: AssetSummary | null = loadedMedia
    ? {
        // Hard coded since we always render the same aspect ratio, regardless of the asset
        aspectRatio: STACK_ASPECT_RATIO,
        aspectRatioDecimal: 1,
        width: loadedMedia.width,
        height: loadedMedia.width,
      }
    : null;

  const balancer = useBalancedMedia({
    asset,
    containerRef,
    enabled: true,
  });

  const stackLayers = count === 1 ? null : count - 1;

  return (
    <AspectContainer
      ref={containerRef}
      className={MEDIA_STACK_HORIZONTAL_CLASSNAME}
      count={count}
      debug={DEBUG}
      isMediaLoaded={loadedMedia !== null}
      equalPadding={equalPadding}
    >
      {DEBUG && <DebugBalancedMedia {...balancer} />}
      <StackContainer
        style={{
          transform: hasBalancedMedia
            ? `scale(${balancer.mediaTransformScale})`
            : undefined,
        }}
      >
        {stackLayers &&
          repeat(stackLayers, '').map((_, index) => {
            return (
              <StackLayer key={index} css={{ backgroundColor: tintColor }}>
                {media.type === 'video' ? (
                  <Media
                    src={media.src}
                    as="video"
                    poster={media.poster}
                    {...DEFAULT_VIDEO_PROPS}
                  />
                ) : (
                  <Media alt={media.alt} src={media.src} />
                )}
              </StackLayer>
            );
          })}
        <TopLayer>
          {media.type === 'video' ? (
            <Media
              src={media.src}
              as="video"
              poster={media.poster}
              {...DEFAULT_VIDEO_PROPS}
            />
          ) : (
            <Media src={media.src} alt={media.alt} draggable={false} />
          )}
        </TopLayer>
      </StackContainer>
    </AspectContainer>
  );
}

const StackContainer = styled(Media.Root, {
  position: 'relative',
  aspectRatio: STACK_ASPECT_RATIO,
  maxHeight: '100%',
  backgroundColor: 'inherit',
});

const TopLayer = styled(Media.Tint, {
  position: 'relative',
  overflow: 'hidden',
  transition: 'opacity $3 $ease, transform 0.6s $ease',
  willChange: 'opacity, transform',
  boxShadow: '$regular0',
  aspectRatio: STACK_ASPECT_RATIO,
});

const StackLayer = styled(Media.Tint, {
  position: 'absolute',
  transition: 'opacity $3 $ease, transform 0.6s $ease',
  willChange: 'opacity, transform',
  boxShadow: '$regular0',
  margin: '0 auto',
});

/**
 * This is the container that can be controlled by parent components.
 * It can be used to control the maximum space that the stack can occupy.
 */
const AspectContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'inherit',
  width: '100%',

  [`${Media.Root}`]: {
    transition: 'opacity $3 $ease, transform 0.6s $ease',
    willChange: 'opacity, transform',
  },

  [`${TopLayer}, ${StackLayer}`]: {
    borderRadius: '$1',
  },

  variants: {
    debug: {
      true: {
        outline: '1px solid $pink3',
      },
      false: {},
    },
    equalPadding: {
      1: {},
      3: { paddingX: MODIFIER },
      5: { paddingX: MODIFIER * 2 },
    },
    count: {
      1: {},
      3: {
        paddingX: MODIFIER,
        [`${StackLayer}`]: {
          // left item
          '&:nth-child(1)': {
            left: -MODIFIER,
            top: MODIFIER,
            height: `calc(100% - 2 * ${MODIFIER}px)`,
            width: `calc(100% - 2 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
          // right item
          '&:nth-child(2)': {
            right: -MODIFIER,
            top: MODIFIER,
            height: `calc(100% - 2 * ${MODIFIER}px)`,
            width: `calc(100% - 2 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
        },
      },
      5: {
        paddingX: MODIFIER * 2,
        [`${StackLayer}`]: {
          // left item
          '&:nth-child(1)': {
            left: -MODIFIER * 2,
            top: MODIFIER * 2,
            height: `calc(100% - 4 * ${MODIFIER}px)`,
            width: `calc(100% - 4 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.6,
            },
          },
          // middle left item
          '&:nth-child(2)': {
            left: -MODIFIER,
            top: MODIFIER,
            height: `calc(100% - 2 * ${MODIFIER}px)`,
            width: `calc(100% - 2 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
          // middle right item
          '&:nth-child(4)': {
            right: -MODIFIER,
            top: MODIFIER,
            height: `calc(100% - 2 * ${MODIFIER}px)`,
            width: `calc(100% - 2 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.8,
            },
          },
          // right item
          '&:nth-child(3)': {
            right: -MODIFIER * 2,
            top: MODIFIER * 2,
            height: `calc(100% - 4 * ${MODIFIER}px)`,
            width: `calc(100% - 4 * ${MODIFIER}px)`,
            [`${Media}`]: {
              opacity: 0.6,
            },
          },
        },
      },
    },
    isMediaLoaded: {
      true: {
        [`${TopLayer}`]: {
          opacity: 1,
          transform: 'scale(1) translateY(0%)',
        },
        [`${StackLayer}`]: {
          transform: 'scale(1) translateY(0)',
          opacity: 1,
          transitionDelay: '300ms',
        },
      },
      false: {
        [`${TopLayer}`]: {
          opacity: 0,
          transform: 'scale(0.98) translateY(2%)',
        },
        [`${StackLayer}`]: {
          opacity: 0,
        },
      },
    },
  },
  compoundVariants: [
    {
      count: 3,
      equalPadding: 5,
      css: { paddingX: MODIFIER * 2 },
    },
    {
      count: 3,
      isMediaLoaded: false,
      css: {
        [`${StackLayer}`]: {
          '&:nth-child(1)': {
            transform: `scale(0.98) translateX(${MODIFIER * 2}px)`,
          },
          '&:nth-child(2)': {
            transform: `scale(0.98) translateX(-${MODIFIER * 2}px)`,
          },
        },
      },
    },
    {
      count: 5,
      isMediaLoaded: false,
      css: {
        [`${StackLayer}`]: {
          '&:nth-child(1)': {
            transform: `scale(0.98) translateX(${MODIFIER * 2}px)`,
          },
          '&:nth-child(2)': {
            transform: `scale(0.98) translateX(${MODIFIER}px)`,
          },
          '&:nth-child(3)': {
            transform: `scale(0.98) translateX(-${MODIFIER * 2}px)`,
          },
          '&:nth-child(4)': {
            transform: `scale(0.98) translateX(-${MODIFIER}px)`,
          },
        },
      },
    },
    {
      count: 5,
      isMediaLoaded: true,
      css: {
        [`${StackLayer}`]: {
          '&:nth-child(even)': {
            transitionDelay: '300ms',
          },
          '&:nth-child(odd)': {
            transitionDelay: '400ms',
          },
        },
      },
    },
  ],
});
