import { CheckIcon, MinusIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import type { CheckboxProps as CheckboxPrimitiveProps } from '@radix-ui/react-checkbox';

import { checkboxAndRadioCss } from 'css/checkboxRadio';

export type CheckboxControlProps = Required<
  Pick<CheckboxPrimitiveProps, 'checked' | 'onCheckedChange'>
>;

// We only support controlled state so that we can override the icon shown when the checkbox is checked.
type CheckboxProps = CheckboxPrimitiveProps & CheckboxControlProps;

function Checkbox(props: CheckboxProps): React.ReactElement {
  const { checked } = props;
  return (
    <Root {...props}>
      <Indicator>
        {checked === 'indeterminate' && <MinusIcon size={1} />}
        {checked === true && <CheckIcon size={1} />}
      </Indicator>
    </Root>
  );
}

const Root = styled(CheckboxPrimitive.Root, {
  ...checkboxAndRadioCss,
  borderRadius: '$2',
});

const Indicator = styled(CheckboxPrimitive.Indicator, {
  display: 'block',
  svg: {
    display: 'block',
  },
});

export type { CheckboxProps };
export default Checkbox;
