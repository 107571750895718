export const checkboxAndRadioCss = {
  width: '$icon3',
  height: '$icon3',
  padding: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  cursor: 'pointer',

  background: '$white100',
  border: '1px solid transparent',

  color: '$black100',
  backgroundColor: '$white100',
  boxShadow: '$regular0',
  willChange: 'transform',
  transition:
    'background-color $1 $ease, border $1 $ease, box-shadow $1 $ease, color $1 $ease, outline $1 $ease, transform $1 $ease',

  '@hover': {
    '&:hover': {
      borderColor: '$black100',
      boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
    },
  },

  '&:active': {
    backgroundColor: '$black5',
    boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
    transform: 'translate3d(0, 1px, 0)',
  },

  '&:focus-visible': {
    borderColor: '$black100',
    outline: '3px solid $black30',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    boxShadow: '$regular0',
    color: '$black40',
    '@hover': {
      '&:hover': {
        borderColor: 'transparent',
        boxShadow: '$regular0',
      },
    },
    '&:active': {
      backgroundColor: '$white100',
      transform: 'none',
    },
  },

  // Disabled
  '&[aria-checked=true]': {
    backgroundColor: '$black100',
    borderColor: '$black100',
    color: '$white100',
    '@hover': {
      '&:hover': {
        boxShadow: '$regular0',
        borderColor: 'transparent',
        backgroundColor: '$black80',
      },
    },
    '&:focus-visible': {
      borderColor: '$white100',
      outline: '3px solid $black30',
    },
    '&:disabled': {
      borderColor: 'transparent',
      backgroundColor: '$black50',
      boxShadow: 'none',
      color: '$white60',
      transform: 'none',
      '&:active': {
        backgroundColor: '$black50',
      },
    },
  },
};
