import { InfoIcon } from '@f8n/icons';
import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { useRef } from 'react';

import VideoPlayer from 'components/VideoPlayer';

import useBalancedMedia from 'hooks/use-balanced-media';
import useHasIntersected from 'hooks/use-has-intersected';
import useLoadedPreviewMedia from 'hooks/use-loaded-preview-media';
import { isNumberType } from 'utils/helpers';
import { getAspectRatioComparisonDescriptor } from 'utils/media-balancing';
import { createClassSelector } from 'utils/styles';

import { PreviewMediaAsset } from 'types/media';

import Media from './Media';
import Text from './base/Text';
import Tooltip from './base/Tooltip';
import DebugBalancedMedia from './debug/DebugBalancedMedia';

const NFT_MEDIA_CLASSNAME = 'nft-media';
export const NFT_MEDIA_SELECTOR = createClassSelector(NFT_MEDIA_CLASSNAME);

// Toggle this on in development to debug the scaling factor.
const DEBUG = false;

/**
 * CSS to allow the media to grow to the max height of the container while preserving its aspect ratio.
 */
const growToMaxHeight = {
  // Prevent the Root from overflowing it's parent.
  [`${Media.Root}`]: {
    maxHeight: '100%',
  },

  // Allow the media to grow to the max height of the container
  [`${Media}`]: {
    height: '100%',
  },
};

/**
 * CSS to allow the media to grow to the max width of the container while preserving its aspect ratio.
 * Note: This assumes that the container has a fixed aspect ratio.
 */
const growToMaxWidth = {
  // No styles are needed here, since the Root will grow based on the aspect ratio of the Media inside of it (assuming that media has an intrinsic size)
  [`${Media.Root}`]: {},

  // Allow the media to grow to the max width of the container
  [`${Media}`]: {
    height: 'auto',
    width: '100%',
  },
};

/**
 * CSS to force the media to grow to the max height of the container. This is used for media with no intrinsic sizing.
 */
const expandToMaxHeight = {
  [`${Media.Root}`]: {
    height: '100%',
  },
};

/**
 * CSS to force the media to grow to the max width of the container. This is used for media with no intrinsic sizing.
 */
const expandToMaxWidth = {
  [`${Media.Root}`]: {
    width: '100%',
  },
};

const NftMediaContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.6s $ease',
  position: 'relative',

  [`${Media.Root}`]: {
    transition: 'opacity $3 $ease, transform 0.6s $ease',
    willChange: 'opacity, transform',
  },

  variants: {
    variant: {
      raised: {
        [`${Media.Root}`]: {
          borderRadius: '$1',
          boxShadow: '$regular1',
        },
      },
      flat: {
        // Using !important to override hover effects
        [`${Media.Root}`]: {
          backgroundColor: 'transparent !important',
          img: {
            backgroundColor: 'inherit !important',
          },
        },
      },
    },
    debug: {
      true: {
        outline: '1px solid $black20',
      },
      false: {},
    },
    /**
     * The aspect ratio of the container compared to the asset.
     * This is used to determine how to fit the asset inside the container, while allowing both
     * to conform to their own aspect ratios.
     */
    mediaShape: {
      wider: growToMaxWidth,
      taller: growToMaxHeight,
      equal: growToMaxHeight,
    },
    intrinsicSizing: {
      /** The rendered media has an intrinsic size */
      media: {},
      /** The rendered media has no intrinsic sizing. This is primarily used for SVGs */
      none: {},
    },
    isMediaLoaded: {
      true: {
        [`${Media.Root}`]: {
          opacity: 1,
        },
      },
      false: {
        [`${Media.Root}`]: {
          opacity: 0,
        },
      },
    },
  },

  compoundVariants: [
    {
      mediaShape: 'wider',
      intrinsicSizing: 'none',
      css: expandToMaxWidth,
    },
    {
      mediaShape: 'taller',
      intrinsicSizing: 'none',
      css: expandToMaxHeight,
    },
    {
      mediaShape: 'equal',
      intrinsicSizing: 'none',
      css: expandToMaxHeight,
    },
  ],
});

type NftMediaProps = {
  containerAspectRatio?: number | null;
  hasBalancedMedia?: boolean;
  media: PreviewMediaAsset;
  variant?: 'raised' | 'flat';
  onClick?: () => void;
  smallprint?: {
    tooltip: string;
    label: string;
  } | null;
};

function NftMedia(props: NftMediaProps) {
  const {
    containerAspectRatio,
    hasBalancedMedia = true,
    media,
    onClick,
    smallprint = null,
    variant = 'flat',
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const hasIntersected = useHasIntersected(containerRef, { threshold: 0.3 });
  const loadedMedia = useLoadedPreviewMedia(media, { enabled: hasIntersected });
  const balancer = useBalancedMedia({
    asset: loadedMedia,
    containerRef,
    enabled: hasBalancedMedia,
  });

  const getTransform = () => {
    const scale = balancer.mediaTransformScale;

    if (isMediaLoaded) {
      return `scale(${scale}) translateY(0%)`;
    } else {
      return `scale(${scale - 0.02}) translateY(2%)`;
    }
  };

  const getMedia = () => {
    if (!loadedMedia) {
      return null;
    }

    if (media.type === 'video') {
      return (
        <VideoPlayer
          controls={media.controls}
          poster={loadedMedia.src}
          src={media.src}
        />
      );
    }

    return <Media src={loadedMedia.src} />;
  };

  const isMediaLoaded = loadedMedia !== null;
  const mediaShape = loadedMedia
    ? getAspectRatioComparisonDescriptor({
        assetAspectDecimal: loadedMedia.aspectRatioDecimal,
        containerAspectDecimal: balancer.containerAspectDecimal,
      })
    : 'equal';

  return (
    <>
      <NftMediaContainer
        ref={containerRef}
        debug={DEBUG}
        className={NFT_MEDIA_CLASSNAME}
        mediaShape={mediaShape}
        /**
         * SVGs have no intrinsic sizing, meaning we need to ensure they have some hard coded width/height.
         * This is different to other media formats, which have an intrinsic size that we can rely on to ensure it renders
         * at a suitable minimum size.
         */
        intrinsicSizing={media.type === 'vector' ? 'none' : 'media'}
        isMediaLoaded={isMediaLoaded}
        style={{
          aspectRatio: isNumberType(containerAspectRatio)
            ? containerAspectRatio
            : undefined,
        }}
        variant={variant}
      >
        {DEBUG && <DebugBalancedMedia {...balancer} />}
        <Media.Root
          style={{
            aspectRatio: loadedMedia ? loadedMedia.aspectRatio : undefined,
            transform: getTransform(),
            cursor: onClick ? 'zoom-in' : undefined,
          }}
          onClick={onClick}
        >
          <Media.Tint>{getMedia()}</Media.Tint>
        </Media.Root>
        {smallprint && (
          <MediaDetailsText isMediaLoaded={isMediaLoaded}>
            <Tooltip content={smallprint.tooltip}>
              <Text
                css={{ display: 'flex', alignItems: 'center', gap: '$1' }}
                size={0}
                lineHeight={0}
                color="dim"
              >
                <span>{smallprint.label}</span>
                <InfoIcon />
              </Text>
            </Tooltip>
          </MediaDetailsText>
        )}
      </NftMediaContainer>
    </>
  );
}

const MediaDetailsText = styled('div', {
  position: 'absolute',
  padding: '$1', // increases hoverable area for tooltips
  bottom: 0, // touching bottom of media
  transform: `translateY(${onGridPx(11)})`,
  transition: '$3 $ease opacity',

  svg: {
    width: 10,
    height: 10,
  },

  variants: {
    isMediaLoaded: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
});

// TODO: rename to emphasize that this is used for any type of artwork, not just NFTs
export default NftMedia;
