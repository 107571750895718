import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import Frame from './Frame';
import FrameGrid, { GRID_ITEM_SKELETON_TARGET } from './FrameGrid';
import FrameGridItemDetails from './FrameGridItemDetails';
import { MOMENT_MEDIA_TARGET } from './MomentMedia';
import { NFT_MEDIA_SELECTOR } from './NftMedia';
import Badge from './base/Badge';
import Box from './base/Box';
import Button from './base/Button';
import Checkbox, { CheckboxControlProps } from './base/Checkbox';

const FRAME_GRID_ITEM_IDENTIFIER = 'frame-grid-item';

const FrameBadge = styled(Badge, {
  backgroundColor: '$black5',
  zIndex: 2, // to appear above Frame media
  backdropFilter: 'blur(10px)',
});

const FrameGridItemRoot = styled(FrameGrid.Item, {
  position: 'relative',
  willChange: 'transform',
  width: '100%',
  height: 'inherit',
  transition: 'transform $1 $ease',

  [`${Frame}`]: {
    flexShrink: 0,
  },

  [`${FrameBadge}`]: {
    position: 'absolute',
    top: '$4',
    left: '$4',
  },

  '@hover': {
    '&:hover': {
      [`${Frame}`]: {
        backgroundColor: '$black10-solid',
      },

      [NFT_MEDIA_SELECTOR]: {
        transform: 'scale(1.015)',
      },
      [MOMENT_MEDIA_TARGET.selector]: {
        transform: 'scale(1.015)',
      },
    },
  },
});

const FrameGridItemDetailsWrapper = styled('div', {
  marginTop: '$3',
  paddingX: '$1',
  maxWidth: '100%',

  '@bp0': {
    marginTop: '$4',
  },
});

const AbsoluteAnchor = styled('a', {
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export type SortBadgeConfig = { icon?: ReactNode; label: string };

type FrameGridItemScaffoldingProps = {
  sortBadge?: SortBadgeConfig;
  /** Details about this Frame, to display below */
  details: ReactNode;
  href?: string;
  /** media to show inside the Frame */
  media: ReactNode;
};

function FrameGridItemBase(props: FrameGridItemScaffoldingProps) {
  const { details, href, media, sortBadge } = props;

  return (
    <FrameGridItemRoot data-name={FRAME_GRID_ITEM_IDENTIFIER}>
      <Frame>
        {sortBadge && (
          <FrameBadge size={1}>
            {sortBadge.icon}
            <span>{sortBadge.label}</span>
          </FrameBadge>
        )}
        {media}
      </Frame>
      <FrameGridItemDetailsWrapper>{details}</FrameGridItemDetailsWrapper>
      {href && (
        <NextLink href={href} passHref prefetch={false}>
          <AbsoluteAnchor />
        </NextLink>
      )}
    </FrameGridItemRoot>
  );
}

export type FrameGridItemSelectableConfig =
  // Used when a singular item is selectable
  | { type?: 'SINGLE'; onClick: () => void }
  // Used when multiple items are selectable
  | ({ type: 'MULTIPLE' } & CheckboxControlProps);

function FrameGridItemSelectable(
  props: FrameGridItemSelectableConfig & { children: React.ReactNode }
) {
  if (props.type === 'MULTIPLE') {
    return (
      <Box
        as="label"
        css={{ minWidth: 0, position: 'relative', cursor: 'pointer' }}
      >
        {props.children}
        <Box css={{ position: 'absolute', right: '$3', top: '$3' }}>
          <Checkbox
            checked={props.checked}
            onCheckedChange={props.onCheckedChange}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Button
      css={{ minWidth: 0, textAlign: 'left', alignSelf: 'flex-start' }}
      variant="base"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}

function FrameGridItemSkeleton(props: { isLandscape?: boolean }) {
  return (
    <FrameGridItemRoot>
      <Frame.Skeleton
        className={
          props.isLandscape
            ? MOMENT_MEDIA_TARGET.className
            : GRID_ITEM_SKELETON_TARGET.className
        }
      />
      <FrameGridItemDetailsWrapper>
        <FrameGridItemDetails.Skeleton />
      </FrameGridItemDetailsWrapper>
    </FrameGridItemRoot>
  );
}

const FrameGridItem = Object.assign(FrameGridItemBase, {
  Selectable: FrameGridItemSelectable,
  Skeleton: FrameGridItemSkeleton,
});

export default FrameGridItem;
