import { ICON_SIZE_MAP } from '@f8n/icons/dist/helpers/size';
import { styled } from '@f8n-frontend/stitches';
import React from 'react';

import { truncateAddress } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { WorldOverview } from 'types/World';

import { Dot } from './Dot';
import { useFrameGridContext } from './FrameGrid';
import PresentedBy from './PresentedBy';
import Box from './base/Box';
import Flex from './base/Flex';
import Heading from './base/Heading';
import Skeleton from './base/Skeleton';
import Text from './base/Text';

export type FrameGridPresentedByWorld = Pick<WorldOverview, 'name'>;
type FrameGridItemDetailsProps = {
  dynamicLabel: React.ReactNode;
  name: string;
  user: UserLight;
  status: React.ReactNode;
  tag: React.ReactNode;
  world?: FrameGridPresentedByWorld;
};

function FrameGridItemDetailsBase(props: FrameGridItemDetailsProps) {
  const { dynamicLabel, name, user, status, tag, world } = props;
  const frameGridContext = useFrameGridContext();
  const isSelectableGrid = frameGridContext.type === 'SELECTABLE';

  const heading = user.name || user.username || truncateAddress(user.publicKey);

  const headingRow = (
    <Box>
      <FrameGridItemDetailsHeading>{heading}</FrameGridItemDetailsHeading>
    </Box>
  );

  const subheadingRow = (
    <Box
      css={{
        position: 'relative',
        zIndex: 2,
        paddingTop: '$1',
        minWidth: 0,
        maxWidth: '100%',
      }}
    >
      <Text
        css={{ maxWidth: '100%', color: '$black60', lineHeight: '1rem' }}
        size={1}
        ellipsis
      >
        {name}
      </Text>
    </Box>
  );

  return (
    <FrameGridItemDetailsRoot isSelectableGrid={isSelectableGrid}>
      <Flex
        css={{
          '&:empty': {
            display: 'none',
          },
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
          alignItems: 'center',
          maxHeight: 14, // hard coded to prevent {tag} from adding more space between {dynamicLabel} and {headingRow}
        }}
      >
        {dynamicLabel}
        {tag}
      </Flex>
      <Box>
        {headingRow}
        {subheadingRow}
      </Box>
      {status}
      {world && <PresentedBy name={world.name} size={0} />}
    </FrameGridItemDetailsRoot>
  );
}

function FrameGridItemDetailsSkeleton(props: { hasDynamicLabel?: boolean }) {
  const { hasDynamicLabel = true } = props;

  return (
    <Flex expandVertical css={{ gap: '$3', minHeight: 65 }}>
      {hasDynamicLabel && <Skeleton.Block css={{ width: 120, height: 14 }} />}
      <Flex expandVertical css={{ gap: '$1' }}>
        {/* Skeleton for user details */}
        <Skeleton.Block css={{ width: 80, height: 14 }} />
        {/* Skeleton for name */}
        <Skeleton.Block css={{ width: 120, height: 14 }} />
      </Flex>
      {/* Skeleton for status / price */}
      <Skeleton.Block css={{ width: 55, height: 14 }} />
    </Flex>
  );
}

function FrameGridItemDetailsHeading(props: { children: string }) {
  return (
    <Heading
      as="h2"
      ellipsis
      size={2}
      lineHeight={0}
      weight="medium"
      color="strong"
    >
      {props.children}
    </Heading>
  );
}

const FrameGridItemDetailsRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '100%',
  gap: '$3',

  '& > *': {
    minWidth: 0,
    maxWidth: '100%',
  },

  variants: {
    isSelectableGrid: {
      true: {
        // Assumes that the parent is clickable
        userSelect: 'none',
      },
      false: {},
    },
  },

  defaultVariants: {
    isSelectableGrid: false,
  },
});

const FrameGridItemDetailsStatus = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  minWidth: 0,
  alignItems: 'center',
});

const FrameGridItemDetailsStatusText = styled(Text, {
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',

  svg: {
    width: ICON_SIZE_MAP[0],
    height: ICON_SIZE_MAP[0],
  },

  variants: {
    color: {
      strong: {},
      dim: {},
      red: {
        color: '$red4',
      },
    },
  },
});
FrameGridItemDetailsStatusText.defaultProps = {
  size: 1,
  lineHeight: 0,
  weight: 'medium',
};

const FrameGridItemDetailsStatusDivider = styled(Dot);
FrameGridItemDetailsStatusDivider.defaultProps = {
  size: 0,
};

/**
 * Use this for Grids that only apply a status row to certain grid items.
 * Adding this prevents the frame and details text from being misaligned when an item with status is rendered next to one without
 */
function FrameGridItemDetailsEmptyStatusRow() {
  return (
    <FrameGridItemDetails.StatusRow>
      <FrameGridItemDetails.StatusText>&nbsp;</FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.StatusRow>
  );
}

const FrameGridItemDetails = Object.assign(FrameGridItemDetailsBase, {
  Root: FrameGridItemDetailsRoot,
  Heading: FrameGridItemDetailsHeading,
  Skeleton: FrameGridItemDetailsSkeleton,
  StatusRow: FrameGridItemDetailsStatus,
  StatusText: FrameGridItemDetailsStatusText,
  EmptyStatusRow: FrameGridItemDetailsEmptyStatusRow,
});

export default FrameGridItemDetails;
