import { styled } from '@f8n-frontend/stitches';
import { createContext, useContext } from 'react';

import { createCssTarget } from 'utils/styles';

import { MEDIA_STACK_SELECTOR } from './MediaStack';
import { MEDIA_STACK_HORIZONTAL_SELECTOR } from './MediaStackHorizontal';
import { MOMENT_MEDIA_TARGET } from './MomentMedia';
import { NFT_GRID_ITEM_UNSUPPORTED_MEDIA_SELECTOR } from './NftGridItemUnsupportedMedia';
import { NFT_MEDIA_SELECTOR } from './NftMedia';

type FrameGridContextConfig = {
  type: 'LINKS' | 'SELECTABLE';
};

const FrameGridContext = createContext<FrameGridContextConfig>({
  type: 'LINKS',
});

export const useFrameGridContext = () => useContext(FrameGridContext);

export const GRID_ITEM_SKELETON_TARGET = createCssTarget(
  'grid-item-skeleton-frame'
);

const NFT_ITEM_SELECTOR = `${NFT_MEDIA_SELECTOR}, ${NFT_GRID_ITEM_UNSUPPORTED_MEDIA_SELECTOR}`;

const MEDIA_ASPECT_RATIO = {
  landscape: '1.5 / 1',
  portrait: '0.85 / 1',
};

const Root = styled('div', {
  display: 'grid',
  gridTemplateColumns: '100%',
  gridAutoRows: '1fr',
  gridColumnGap: '$6',
  gridRowGap: '$8',

  [GRID_ITEM_SKELETON_TARGET.selector]: {
    aspectRatio: MEDIA_ASPECT_RATIO.portrait,
  },

  [NFT_ITEM_SELECTOR]: {
    aspectRatio: MEDIA_ASPECT_RATIO.portrait,
  },

  [MEDIA_STACK_SELECTOR]: {
    aspectRatio: MEDIA_ASPECT_RATIO.portrait,
  },

  [MEDIA_STACK_HORIZONTAL_SELECTOR]: {
    aspectRatio: MEDIA_ASPECT_RATIO.portrait,
  },

  [MOMENT_MEDIA_TARGET.selector]: {
    aspectRatio: 8 / 5,
  },

  variants: {
    maxColumns: {
      1: {},
      2: {
        '@bp0': {
          gridTemplateColumns: `repeat(2, 1fr)`,
          [GRID_ITEM_SKELETON_TARGET.selector]: {
            aspectRatio: MEDIA_ASPECT_RATIO.landscape,
          },

          [NFT_ITEM_SELECTOR]: {
            aspectRatio: MEDIA_ASPECT_RATIO.landscape,
          },

          [MEDIA_STACK_SELECTOR]: {
            aspectRatio: MEDIA_ASPECT_RATIO.landscape,
          },

          [MEDIA_STACK_HORIZONTAL_SELECTOR]: {
            aspectRatio: MEDIA_ASPECT_RATIO.landscape,
          },
        },
      },
      3: {
        '@bp0': {
          gridTemplateColumns: `repeat(2, 1fr)`,
        },
        '@bp2': {
          gridTemplateColumns: `repeat(3, 1fr)`,
        },
      },
      4: {
        '@bp0': {
          gridTemplateColumns: `repeat(2, 1fr)`,
        },
        '@bp2': {
          gridTemplateColumns: `repeat(3, 1fr)`,
        },
        '@bp3': {
          gridTemplateColumns: `repeat(4, 1fr)`,
        },
      },
      5: {
        '@bp0': {
          gridTemplateColumns: `repeat(2, 1fr)`,
        },
        '@bp2': {
          gridTemplateColumns: `repeat(3, 1fr)`,
        },
        '@bp3': {
          gridTemplateColumns: `repeat(4, 1fr)`,
        },
        '@bp4': {
          gridTemplateColumns: `repeat(5, 1fr)`,
        },
      },
    },
  },

  defaultVariants: {
    maxColumns: 3,
  },
});

const Item = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: 0,
  transition: 'opacity $2 $ease, transform $2 $ease',
});

const FrameGrid = {
  Item,
  Provider: FrameGridContext.Provider,
  Root,
};

export default FrameGrid;
