import { styled } from '@f8n-frontend/stitches';

import { ArtworkUnsupportedMessageWrapper } from 'components/artworks/media/ArtworkUnsupportedMessageWrapper';

import AssetUnsupportedIcon from 'assets/icons/asset-unsupported-icon.svg';
import { getNoAssetArtworkLabel } from 'utils/artwork/artwork';
import { createCanvasBackground, createCssTarget } from 'utils/styles';

const { className, selector } = createCssTarget(
  'nft-grid-item-unsupported-media'
);
export const NFT_GRID_ITEM_UNSUPPORTED_MEDIA_SELECTOR = selector;

export default function NftGridItemUnsupportedMedia() {
  return (
    <UnsupportedMediaContainer
      className={className}
      style={createCanvasBackground({
        hasTransparentBackground: true,
        layering: 'darken',
      })}
    >
      <IconWrapper
        aria-label={getNoAssetArtworkLabel()}
        backgroundColor="light"
      >
        <AssetUnsupportedIcon />
      </IconWrapper>
    </UnsupportedMediaContainer>
  );
}

const UnsupportedMediaContainer = styled('div', {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: 'inherit',
});

const IconWrapper = styled(ArtworkUnsupportedMessageWrapper, {
  width: '56px',
  height: '56px',
  transition: 'opacity $1',
  marginY: 'auto',
});
