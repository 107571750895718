import { darkMode, styled } from '@f8n-frontend/stitches';

import { NFT_MEDIA_SELECTOR } from './NftMedia';
import Skeleton from './base/Skeleton';

const FrameBase = styled('div', {
  borderRadius: '$1',
  position: 'relative',
  backgroundColor: '$black5-solid',
  transition: 'background-color $1 $ease',
  overflow: 'hidden',

  [darkMode]: {
    backgroundColor: '$black10',
  },

  variants: {
    isInteractive: {
      true: {
        '@hover': {
          '&:hover': {
            [NFT_MEDIA_SELECTOR]: {
              transform: 'scale(1.015)',
            },
            backgroundColor: '$black10-solid',

            [darkMode]: {
              backgroundColor: '$black10',
            },
          },
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    isInteractive: true,
  },
});

const FrameSkeleton = styled(Skeleton.Block, {
  width: '100%',
  borderRadius: '$2',
});

const Frame = Object.assign(FrameBase, {
  Skeleton: FrameSkeleton,
});

export default Frame;
